import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../components/Layout';
import Mapbox from '../../mapbox'
import Calculate from '../../util/calculate';


import Sidetab from '../../components/Sidetab'
import Overview from './map_content/Overview'
import MapAreaView from './map_content/MapAreaView'
import SchoolView from './map_content/SchoolView'
import CompareData from './map_content/CompareData'

import '../index.css';

import marker_school from '/images/marker/school_ss.svg'

import api from '../../util/api';
import election_text from '../../util/election_text.json';
import axios from 'axios';

const FullMapElec = (props) => {
  const [open, setOpen] = useState(true)
  const [user_data, setUserData] = useState(null)
  const [tabvalue, setTabValue] = useState(0);
  const [data, setData] = useState({
    tumbon_name: null,
    city_name: null,
    province_name: null,
    tumbonid: null,

    class_k: 0,
    class_p: 0,
    class_m: 0,
    class_h: 0,
    class_u: 0,

    welfareID_forced: 0,
    welfareID_sex_buiness: 0,
    welfareID_abandoned: 0,
    welfareID_detention_juvenile: 0,
    welfareID_street_children: 0,
    welfareID_aids: 0,
    welfareID_minority: 0,
    welfareID_child_abuse: 0,
    welfareID_jon: 0,
    welfareID_drug: 0,
    welfareID_other: 0,
    welfareID_orphan: 0,
    welfareID_family: 0,
    disability_double: 0,
    disability_speech: 0,
    disability_visually: 0,
    disability_learning: 0,
    disability_hearing: 0,
    disability_behavioral: 0,
    disability_physically: 0,
    disability_intellectually: 0,
    disability_autism: 0,

    sum_sungkud_cct: 0,
    sum_sungkud_pbb: 0,
    sum_sungkud_dla: 0,
    sum_sungkud_opec: 0,
    sum_sungkud_onab: 0,
    sum_sungkud_other: 0,

    sum_school_sizeXL: 0,
    sum_school_sizeX: 0,
    sum_school_sizeL: 0,
    sum_school_sizeS: 0,

    teacher_total: 0,
    pc: 0,
    notebook: 0,
    school_has_electricity: 0,
    school_not_electricity: 0,
    school_has_electricity_service_area: 0,
    school_not_electricity_service_area: 0,

    school_has_internet: 0,
    school_not_internet: 0,

    school_has_water: 0,
    school_not_water: 0,

    agricultural_chemistry_air_problem: 0,
    factory_air_problem: 0,
    traffic_air_problem: 0,
    garbage_air_problem: 0,

    agricultural_chemistry_water_problem: 0,
    factory_water_problem: 0,
    traffic_water_problem: 0,
    garbage_water_problem: 0,

    agricultural_chemistry_noise_problem: 0,
    factory_noise_problem: 0,
    traffic_noise_problem: 0,
    garbage_noise_problem: 0,

    school_ground: 0,
    school_island: 0,
    school_high: 0,
    school_hill: 0,
    school_border: 0,

    class_k: 0,
    class_p: 0,
    class_m: 0,
    class_h: 0,
    class_u: 0,

    cct: 0,
    krt: 0,
    vec: 0,
    tps: 0,
    target_group: 0,
    tsqp: 0,
    abe: 0,
    oosc: 0,
    welfare: 0,
    disablility: 0,
    total: 0,
    total_oosc: 0,
    school: 0,
    province20: 0,
    plan_teaher: 0,
    ecd: 0,
    sum_oosc: 0,
    total_fund: 0,
    listschool: [],
    tumbon: [],
  })
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);

  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  const [filter, setFilter] = React.useState({
    view: 'representative',
    year: "2566",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_select: [],
    tumbon_select: [],
    school_select: [],
    list_province: [],
    list_city: [],
    list_tumbon: [],
    list_school: [],
    school_address: [],
    zone: null,
    user_province: null,
    user_zone: null,
    user_role: [],
  });

  useEffect(async () => {
    let user_data = await api.getProfileDb()
    let user_p = '10'
    let user_z = '1'
    if (user_data.ok) {
      if (user_data.profile.profile.provinceId) user_p = user_data.profile.profile.provinceId
      if (user_data.profile.profile.zoneId) user_z = user_data.profile.profile.zoneId
    }

    let data_json = await api.getInitData(`/electionzone/P${user_p}-${user_z}`)
    data_json = data_json.data
    let list_province = [...new Set(election_text.map(item => { return { province: item.province, zone: item.zone.toString() } }))]
    list_province = list_province.sort(function (a, b) {
      if (a.province < b.province) {
        return -1;
      }
      if (a.province > b.province) {
        return 1;
      }
      return 0;
    });
    let list_city = [...new Set(data_json.tumbon.map(item => item.city_name))]
    let list_tumbon = [...new Set(data_json.tumbon.map(item => item.tumbon_name))]
    let list_school = data_json.listschool.map(item => item.hostname)
    let school_address = []
    for (let i = 0; i < data_json.tumbon.length; i++) {
      for (let j = 0; j < data_json.tumbon[i].listschool.length; j++)
        school_address.push({
          ...data_json.tumbon[i].listschool[j],
          tumbon_id: data_json.tumbon[i].tumbonid,
          tumbon_name: data_json.tumbon[i].tumbon_name,
          city_id: data_json.tumbon[i].cityid,
          city_name: data_json.tumbon[i].city_name,
        })
    }
    // console.log('data_json',data_json)
    // data_json.welfare = (data_json.welfareID_forced + 
    //   data_json.welfareID_sex_buiness + 
    //   data_json.welfareID_abandoned + 
    //   data_json.welfareID_detention_juvenile + 
    //   data_json.welfareID_street_children + 
    //   data_json.welfareID_aids + 
    //   data_json.welfareID_minority + 
    //   data_json.welfareID_child_abuse + 
    //   data_json.welfareID_jon + 
    //   data_json.welfareID_drug + 
    //   data_json.welfareID_other + 
    //   data_json.welfareID_orphan)
    // setUserData(user_data.profile)
    setData(data_json)
    setFilter({
      ...filter,
      user_province: user_p, user_zone: user_z,
      province_name: data_json.province_name, zone: data_json.zone,
      user_role: user_data.profile.roles,

      list_province: list_province, list_city: list_city, list_tumbon: list_tumbon, list_school: list_school,
      city_select: list_city, tumbon_select: list_tumbon, school_select: list_school,
      school_address: school_address
    })
  }, [])

  // useEffect(async () => {
  //   if (user_data) {
  //     let user_p = '10'
  //     let user_z = '1'
  //     if (user_data.roles.includes('eef') || user_data.roles.includes('admin')) {
  //       if (filter.user_zone && filter.user_province) {
  //         user_p = filter.user_province
  //         user_z = filter.user_zone
  //       } else {
  //         if (user_data.profile.provinceId) user_p = user_data.profile.provinceId
  //         if (user_data.profile.zoneId) user_z = user_data.profile.zoneId
  //       }
  //     } else {
  //       if (user_data.profile.provinceId) user_p = user_data.profile.provinceId
  //       if (user_data.profile.zoneId) user_z = user_data.profile.zoneId
  //     }

  //     let data_json = await api.getInitData(`/electionzone/P${user_p}-${user_z}`)
  //     data_json = data_json.data
  //     let list_province = [...new Set(election_text.map(item => { return { province: item.province, zone: item.zone.toString() } }))]
  //     list_province = list_province.sort(function (a, b) {
  //       if (a.province < b.province) {
  //         return -1;
  //       }
  //       if (a.province > b.province) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     let list_city = [...new Set(data_json.tumbon.map(item => item.city_name))]
  //     let list_tumbon = [...new Set(data_json.tumbon.map(item => item.tumbon_name))]
  //     let list_school = data_json.listschool.map(item => item.hostname)
  //     let school_address = []
  //     for (let i = 0; i < data_json.tumbon.length; i++) {
  //       for (let j = 0; j < data_json.tumbon[i].listschool.length; j++)
  //         school_address.push({
  //           ...data_json.tumbon[i].listschool[j],
  //           tumbon_id: data_json.tumbon[i].tumbonid,
  //           tumbon_name: data_json.tumbon[i].tumbon_name,
  //           city_id: data_json.tumbon[i].cityid,
  //           city_name: data_json.tumbon[i].city_name,
  //         })
  //     }
  //     setData(data_json)
  //     setFilter({
  //       ...filter,
  //       user_province: user_p, user_zone: user_z,
  //       province_name: data_json.province_name, zone: data_json.zone,

  //       list_province: list_province, list_city: list_city, list_tumbon: list_tumbon, list_school: list_school,
  //       city_select: list_city, tumbon_select: list_tumbon, school_select: list_school,
  //       school_address: school_address
  //     })
  //     setTabValue(0)
  //   }
  // }, [filter.user_province, filter.user_zone])

  useEffect(() => {
    if (data.tumbon.length > 0) {
      let data_filter = data.tumbon.filter(item => filter.tumbon_select.includes(item.tumbon_name))
      let school_filter = data.listschool.filter(item => filter.school_select.includes(item.hostname))
      let response = onProcessData(filter, convertMapContent(data_filter))
      setDataMap([
        { id: 'border', layer: filter.view, data: response.data },
        { id: 'school_ss', layer: 'marker', data: (tabvalue === 0 || tabvalue === 1) ? [] : school_filter, icon: marker_school },
      ])
    }
  }, [filter.tumbon_select, filter.school_select, tabvalue])

  const updateFilter = (res) => {
    return setFilter({ ...res })
  }

  let tab_content = [
    {
      title: 'ภาพรวม',
      content: <Overview
        data={data}
        filter={filter}
        setFilter={updateFilter} />
    },
    {
      title: 'ตำบล',
      content: <MapAreaView
        data={data}
        filter={filter}
        setFilter={updateFilter}
      />
    },
    {
      title: 'โรงเรียน',
      content: <SchoolView
        data={data}
        filter={filter}
        setFilter={updateFilter}
      />
    },
    {
      title: 'เปรียบเทียบข้อมูล',
      content: <CompareData
        data={data}
        filter={filter}
        setFilter={updateFilter}
      />
    }
  ]

  const convertMapContent = (area_data) => {
    const tumbon = area_data.map(item => { return { province: item.province_name, city: item.city_name, tumbon: item.tumbon_name, total_fund: 0 } });
    area_data.forEach((item, idx) => {
      let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
      if (index !== -1) {
        let total_fund = (
          (item.cct ? item.cct : 0) +
          (item.vec ? item.vec : 0) +
          (item.krt ? item.krt : 0) +
          (item.tps ? item.tps : 0) +
          (item.target_group ? item.target_group : 0) +
          (item.abe ? item.abe : 0) +
          (item.oosc ? item.oosc : 0) +
          (item.tsqp ? item.tsqp : 0)
        )
        !tumbon[index]['school_all'] ? tumbon[index]['school_all'] = item.listschool.length : tumbon[index]['school_all'] += item.listschool.length
        console.log("total", item['total'], tumbon[index]['total'])
        !tumbon[index]['total'] ? tumbon[index]['total'] = item.total : tumbon[index]['total'] += item.total
        !tumbon[index]['disablility'] ? tumbon[index]['disablility'] = item.disablility : tumbon[index]['disablility'] += item.disablility
        !tumbon[index]['welfare'] ? tumbon[index]['welfare'] = item.welfare : tumbon[index]['welfare'] += item.welfare
        !tumbon[index]['total_oosc'] ? tumbon[index]['total_oosc'] = item.total_oosc : tumbon[index]['total_oosc'] += item.total_oosc

        !tumbon[index]['cct'] ? tumbon[index]['cct'] = item.cct : tumbon[index]['cct'] += item.cct
        !tumbon[index]['vec'] ? tumbon[index]['vec'] = item.vec : tumbon[index]['vec'] += item.vec
        !tumbon[index]['krt'] ? tumbon[index]['krt'] = item.krt : tumbon[index]['krt'] += item.krt
        !tumbon[index]['tps'] ? tumbon[index]['tps'] = item.tps : tumbon[index]['tps'] += item.tps
        !tumbon[index]['target_group'] ? tumbon[index]['target_group'] = item.target_group : tumbon[index]['target_group'] += item.target_group

        !tumbon[index]['abe'] ? tumbon[index]['abe'] = item.abe : tumbon[index]['abe'] += item.abe
        !tumbon[index]['ecd'] ? tumbon[index]['ecd'] = item.ecd : tumbon[index]['ecd'] += item.ecd
        !tumbon[index]['tsqp'] ? tumbon[index]['tsqp'] = item.tsqp : tumbon[index]['tsqp'] += item.tsqp

        !tumbon[index]['total_fund'] ? tumbon[index]['total_fund'] = total_fund : tumbon[index]['total_fund'] += total_fund
      }
    })
    return tumbon
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['total_fund'] / element['total_fund'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['total_fund'] / result.reduce((acc, curent) => acc + curent.total_fund, 0)) * 100)
      }
      return element
    })

    let data_color = [
      // { color: '#FF9A7B', value: (average(result) * 1.5), count: 0, text: `มากกว่า ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FFAE93', value: (average(result)), count: 0, text: `${average(result).toFixed(2)}% - ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FECAB5', value: (average(result) / 2), count: 0, text: `${(average(result) / 2).toFixed(2)}% - ${average(result).toFixed(2)}%` },
      // { color: '#FFEEE7', value: (0), count: 0, text: `น้อยกว่า ${(average(result) / 2).toFixed(2)}%` },

      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.total_fund)), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.total_fund)), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.total_fund)), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.total_fund)
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  const handleChangeTab = (event, newValue, pretab) => {
    setTabValue(newValue);
  };

  return (
    <Layout isHideFooter>
      <div className='relative overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>
          <Mapbox
            id={'map-cct'}
            filter={filter}
            data={data_map}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            notic_title={'จำนวนโรงเรียนในประเทศไทย'}
          // onclickBorder={onclickBorder}
          />
          <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>สัดส่วนนักเรียนที่ได้รับทุน</span>
              <div className='flex'>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                  </div>
                  <div class="font-bold text-left">
                    มาก
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                  </div>
                  <div class="font-bold text-right">
                    น้อย
                  </div>
                </div>
                <div className='ml-4  flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                  </div>
                  <div class="font-bold text-center">
                    ไม่มีข้อมูล
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidetab
          title="การดำเนินงานของ กสศ. ในพื้นที่ของท่าน"
          tab={tab_content}
          open={open}
          titleRef=''
          subjectTitleRef='*ประมวลผล ณ เดือนสิงหาคม 2567'
          tabvalue={tabvalue}
          setOpen={setOpen}
          handleChangeTab={(event, res) => handleChangeTab(event, res, tabvalue)}
        />
      </div>
    </Layout >
  )
}

export default FullMapElec
