import React, { useState, useEffect } from 'react';
import {
  convertProvinceTextToId,
  convertProvinceIdToText
} from '../../../mapbox/mapboxfn'
import { Select, Collapse, Modal } from 'antd';
import InfoIcon from '@mui/icons-material/Info';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Chart from '../../../components/Chart';
import ProgressProbView from '../../../components/ProgressProbView';

import IconStudent from '/images/students_35x35.png';
import IconSchool from '/images/school_35x35.png';
import IconResource from '/images/Windmill_35x35.png';

import imageSchoolS from '/images/krt-school-s.png';
import imageSchoolL from '/images/krt-school-l.png';
import imageSchoolX from '/images/krt-school-x.png';
import imageSchoolXL from '/images/krt-school-xl.png';

import imageBorder from '/images/krt-border.png';
import imageGround from '/images/krt-ground.png';
import imageHigh from '/images/krt-high.png';
import imageHill from '/images/krt-hill.png';
import imageIsload from '/images/krt-isload.png';

import obec_icon from '/images/home12.png'
import dla_icon from '/images/home10.png'
import bpp_icon from '/images/home8.png'
import opec_icon from '/images/home13.png'
import onab_icon from '/images/home11.png'
import sp_icon from '/images/home14.png'

import ComputerIcon from '@mui/icons-material/Computer';
import BoltIcon from '@mui/icons-material/Bolt';
import FactoryIcon from '@mui/icons-material/Factory';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';
import OpacityIcon from '@mui/icons-material/Opacity';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';

const { Option } = Select;
const { Panel } = Collapse;

const Overview = (props) => {
  let { filter, setFilter, data } = props
  const [tab, setTab] = useState(0);
  const [ismodalref, setModalRef] = useState(false)
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const Card = ({ title, value, unit }) => {
    return <div className='w-full h-[170px] text-center rounded-md shadow-md bg-white px-4 py-4'>
      <div className='h-full my-4'>
        <h3 className='text-lg xl:text-xl m-0'>{title}</h3>
        <h1 className='font-bold text-2xl xl:text-3xl m-0'>{value}</h1>
        <h3 className='font-bold text-lg xl:text-xl m-0'>{unit}</h3>
      </div>
    </div>
  }

  const ExpandCard = ({ summary, detail }) => {
    return (
      <div className='my-4'>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            className=' border-b border-neutral-400'
            sx={{ borderBottom: "1px dotted gray" }}
            expandIcon={detail ? <ExpandMoreIcon /> : <></>}
            aria-controls={`${summary.title}-content`}
            id={`${summary.title}-header`}
          >
            <div className='flex justify-between w-full'>
              <h1 className='text-lg md:text-2xl font-bold m-0'>
                {summary?.title}
              </h1>
              <h1 className='text-xl md:text-3xl font-bold m-0 pr-4'>
                {summary?.value?.toLocaleString("en-US")}
                <span className='pl-4'>{summary?.unit}</span>
              </h1>
            </div>
          </AccordionSummary>
          {detail && <AccordionDetails className='bg-neutral-100'>
            {
              detail.map((ele, idx) => {
                return (
                  <div key={ele.title + '_' + idx} className='flex justify-between my-2 mx-4'>
                    <span className='text-left text-base md:text-lg font-bold'>{ele.title}</span>
                    <span className='text-right'>
                      <span className='text-lg md:text-2xl font-bold'>{ele.value?.toLocaleString("en-US")}</span>
                      <span className='pl-2 text-xl font-bold'>{ele.unit}</span>
                    </span>
                  </div>
                )
              })
            }
          </AccordionDetails>}
        </Accordion >
      </div>
    )
  }

  // const reduceValueComma = (data,key) => {
  //   return data.reduce((acc,data) => acc + data.,0)
  // }

  return (
    <div>
      {/* {
        (filter.user_role.includes('eef') || filter.user_role.includes('admin')) && <div className='grid grid-cols-9'>
          <div className='col-span-9 md:col-span-3 md:col-start-4 flex flex-row mb-2'>
            <h5 className="text-base mx-auto my-auto font-semibold">เลือกจังหวัด: </h5>
            <Select
              className='new-design-select'
              showSearch
              placeholder="เลือกจังหวัด"
              maxTagCount='responsive'
              value={convertProvinceIdToText(filter.user_province) + " " + filter.user_zone}
              style={{ width: '60%', margin: 4 }}
              onChange={(val) => {
                setFilter({
                  ...filter,
                  user_province: convertProvinceTextToId(val.split("_")[0]),
                  user_zone: val.split("_")[1],
                })
              }}
            >
              {
                filter.list_province.map(ele => {
                  return <Option value={ele.province + "_" + ele.zone}>{ele.province + " " + ele.zone}</Option>
                })
              }
            </Select>
          </div>
        </div>
      } */}
      <h2 className='text-center font-bold text-lg xl:text-xl'>
        จำนวนเด็กและเยาวชนวัยเรียน
      </h2>
      <h1 className='text-center font-bold text-2xl xl:text-3xl text-orange-600'>
        {`" ${filter.province_name} เขต ${filter.zone} "`}
      </h1>
      <h1 className='text-center font-bold text-3xl xl:text-4xl text-blue-700'>
        {`${(data.total + data.total_oosc).toLocaleString('en-Us')} คน`}
      </h1>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <div className='col-span-1'>
          <Card title="เด็กในระบบการศึกษาขั้นพื้นฐาน" value={<span className='text-[#048967]'>{data.total.toLocaleString('en-Us')}</span>} unit={'คน'} />
        </div>
        <div className='col-span-1'>
          <Card title={<span>เด็กและเยาวชนที่ไม่มีข้อมูล<br />ในระบบการศึกษา (3 - 17 ปี)</span>} value={<span className='text-[#F68631]'>{data.total_oosc.toLocaleString('en-Us')}</span>} unit={'คน'} />
        </div>
      </div>
      <div className='w-full rounded-sm shadow-md bg-white px-12 py-4 my-4'>
        <h1 className='text-center font-bold text-2xl xl:text-3xl'>{filter.province_name}</h1>
        <div className='border-b border-gray-400 w-full m-auto my-2'></div>
        <div className='grid grid-cols-8'>
          <h2 className='col-span-8 md:col-span-4 text-left md:text-left'>เขตเลือกตั้งของท่านครอบคลุมตำบลจำนวน</h2>
          <h2 className='col-span-6 md:col-span-3 text-left md:text-right text-[#0E369C] text-xl font-bold'>{data.tumbon.length.toLocaleString('en-Us')}</h2>
          <h2 className='col-span-2 md:col-span-1 text-right md:text-right'>ตำบล</h2>
          <h2 className='col-span-8 md:col-span-4 text-left md:text-left'>เด็กและเยาวชนทั้งหมดในเขตเลือกตั้งของท่าน</h2>
          <h2 className='col-span-6 md:col-span-3 text-left md:text-right text-[#0E369C] text-xl font-bold'>{(data.total + data.total_oosc).toLocaleString('en-Us')}</h2>
          <h2 className='col-span-2 md:col-span-1 text-right md:text-right'>คน</h2>
          <h2 className='col-span-8 md:col-span-4 text-left md:text-left'>จำนวนโรงเรียนทั้งหมดในเขตเลือกตั้งของท่าน</h2>
          <h2 className='col-span-6 md:col-span-3 text-left md:text-right text-[#0E369C] text-xl font-bold'>{(data.sum_sungkud_cct +
            data.sum_sungkud_pbb + data.sum_sungkud_dla + data.sum_sungkud_opec +
            data.sum_sungkud_onab + data.sum_sungkud_other).toLocaleString('en-Us')}</h2>
          <h2 className='col-span-2 md:col-span-1 text-right md:text-right'>โรงเรียน</h2>
        </div>
      </div>
      <div className='text-center mx-auto'>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="icon position tabs example"
          centered
        >
          <Tab icon={<img src={IconStudent} />} label="ประชากรวัยเรียน" />
          <Tab icon={<img src={IconSchool} />} label="โรงเรียน" />
          <Tab icon={<img src={IconResource} />} label="ทรัพยากร" />
        </Tabs>
      </div>
      {
        tab === 0 &&
        <div className='w-full mx-auto'>
          <ExpandCard
            summary={{ title: 'เด็กในระบบการศึกษาขั้นพื้นฐาน', value: (data.total).toLocaleString('en-Us'), unit: 'คน' }}
            detail={[
              { title: 'อนุบาล', value: (data.class_k).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'ประถมศึกษา', value: (data.class_p).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'มัธยมศึกษาตอนต้น', value: (data.class_m).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'มัธยมศึกษาตอนปลาย', value: (data.class_h).toLocaleString('en-Us'), unit: 'คน' },
              // { title: 'อุดมศึกษา', value: (data.class_u).toLocaleString('en-Us'), unit: 'คน' },
            ]} />
          <ExpandCard
            summary={{ title: 'นักเรียนด้อยโอกาส', value: (data.welfare).toLocaleString('en-Us'), unit: 'คน' }}
            detail={[
              { title: 'เด็กยากจน', value: (data.welfareID_jon).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'เด็กกำพร้า', value: (data.welfareID_orphan).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'ชนกลุ่มน้อย', value: (data.welfareID_minority).toLocaleString('en-Us'), unit: 'คน' },
              // { title: 'ทำงานรับผิดชอบตนเองเเละครอบครัว', value: (data.welfareID_family).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'เด็กถูกทอดทิ้ง', value: (data.welfareID_abandoned).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'เด็กที่ถูกทำร้ายทารุณ', value: (data.welfareID_child_abuse).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'เด็กเร่ร่อน', value: (data.welfareID_street_children).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'เด็กถูกบังคับให้ขายเเรงงาน', value: (data.welfareID_forced).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'เด็กที่มีปัญหาเกี่ยวกับยาเสพติด', value: (data.welfareID_drug).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'เด็กในสถานพินิจและคุ้มครองเด็กเยาวชน', value: (data.welfareID_detention_juvenile).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'ผลกระทบจากเอดส์', value: (data.welfareID_aids).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'เด็กที่อยู่ในธุรกิจทางเพศ', value: (data.welfareID_sex_buiness).toLocaleString('en-Us'), unit: 'คน' },
            ]} />
          <ExpandCard
            summary={{ title: 'นักเรียนพิการ', value: (data.disablility).toLocaleString('en-Us'), unit: 'คน' }}
            detail={[
              { title: 'บุคคลที่มีความบกพร่องทางการเรียนรู้', value: (data.disability_learning).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'บุคคลที่มีความบกพร่องทางสติปัญญา', value: (data.disability_intellectually).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'บุคคลที่มีความบกพร่องทางร่างการหรือสุขภาพ', value: (data.disability_physically).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'บุคคลพิการซ้อน', value: (data.disability_double).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'บุคคลที่มีความบกพร่องทางพฤติกรรมหรืออารมณ์', value: (data.disability_behavioral).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'บุคคลออทิสติก', value: (data.disability_autism).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'บุคคลที่มีความบกพร่องทางการพูดเเละภาษา', value: (data.disability_speech).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'บุคคลที่มีความบกพร่องทางการมองเห็น', value: (data.disability_visually).toLocaleString('en-Us'), unit: 'คน' },
              { title: 'บุคคลที่มีความบกพร่องทางการได้ยิน', value: (data.disability_hearing).toLocaleString('en-Us'), unit: 'คน' },
            ]} />
          <ExpandCard summary={{ title: 'เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (3 - 17 ปี)', value: (data.total_oosc).toLocaleString('en-Us'), unit: 'คน' }} />
        </div>
      }
      {
        tab === 1 &&
        <div className='w-full mx-auto'>
          <div>
            <p className='text-xl mb-1'>
              โรงเรียนแบ่งตามสังกัด
            </p>
            <div className='grid grid-cols-6 gap-2'>
              {
                [
                  { icon: obec_icon, title: 'สพฐ.', value: (data.sum_sungkud_cct).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                  { icon: dla_icon, title: 'อปท.', value: (data.sum_sungkud_dla).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                  { icon: bpp_icon, title: 'ตชด.', value: (data.sum_sungkud_pbb).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                  { icon: opec_icon, title: 'สช.', value: (data.sum_sungkud_opec).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                  { icon: onab_icon, title: 'พศ.', value: (data.sum_sungkud_onab).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                  { icon: sp_icon, title: 'อื่นๆ', value: (data.sum_sungkud_other).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                ].map((ele, idx) => {
                  return <div key={`${ele.title}-card-${idx}`} className='col-span-2 md:col-span-2 xl:col-span-1'>
                    <div className='w-full py-4 border rounded-md shadow-lg text-center bg-white'>
                      <img className='mx-auto' src={ele.icon} width={60} />
                      <p className='my-1 text-xl xl:text-2xl'>{ele.title}</p>
                      <p className='my-1 text-2xl xl:text-3xl font-bold'>{ele.value}</p>
                      <p className='my-1 text-lg xl:text-xl'>{ele.unit}</p>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
          <div>
            <p className='text-xl mb-1'>
              โรงเรียนปลายทางแบ่งตามขนาดโรงเรียน
            </p>
            <div className='grid grid-cols-12 gap-2'>
              {
                [
                  { icon: imageSchoolS, title: 'รร.ขนาดเล็ก', subtitle: 'จำนวนโรงเรียน', value: (data.sum_school_sizeS).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageSchoolL, title: 'รร.ขนาดกลาง', subtitle: 'จำนวนโรงเรียน', value: (data.sum_school_sizeL).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageSchoolX, title: 'รร.ขนาดใหญ่', subtitle: 'จำนวนโรงเรียน', value: (data.sum_school_sizeX).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageSchoolXL, title: 'รร.ขนาดใหญ่พิเศษ', subtitle: 'จำนวนโรงเรียน', value: (data.sum_school_sizeXL).toLocaleString('en-Us'), unit: 'รร.' },
                ].map((ele, idx) => {
                  return <div key={`${ele.title}-card-${idx}`} className='col-span-12 md:col-span-6 flex-col'>
                    <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
                      <img className="flex self-center xl:mx-4 w-[72px]" src={ele.icon} alt="images" />
                      <div className='flex flex-1 flex-col'>
                        <span className='text-xl font-bold text-center mb-0'>{ele.title}</span>
                        <span className='text-sm font-bold text-center'>{ele.subtitle}</span>
                        <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{`${ele.value} ${ele.unit}`}</p>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
          <div>
            <p className='text-xl mb-1 mt-5'>
              โรงเรียนปลายทางแบ่งตามภูมิประเทศที่ตั้งโรงเรียน
            </p>
            <div className='grid grid-cols-12 gap-2'>
              {
                [
                  { icon: imageGround, title: 'พื้นที่ราบ', subtitle: 'จำนวนโรงเรียน', value: (data.school_ground).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageHill, title: 'พื้นที่เขา', subtitle: 'จำนวนโรงเรียน', value: (data.school_hill).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageHigh, title: 'พื้นที่สูง', subtitle: 'จำนวนโรงเรียน', value: (data.school_high).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageBorder, title: 'พื้นที่ติดชายแดน', subtitle: 'จำนวนโรงเรียน', value: (data.school_border).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageIsload, title: 'พื้นที่เกาะ', subtitle: 'จำนวนโรงเรียน', value: (data.school_island).toLocaleString('en-Us'), unit: 'รร.' },
                ].map((ele, idx) => {
                  return <div className={`col-span-6 lg:col-span-4 ${idx === 3 ? 'lg:col-start-3' : (idx === 4 ? 'lg:col-start-7' : '')} flex-col px-1`}>
                    <div className='relativef flex flex-col  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
                      <span className='text-lg font-bold text-center mb-0'>{ele.title}</span>
                      <div className='flex flex-row justify-evenly '>
                        <img className="flex self-center" src={ele.icon} alt="image for banner" width={'56px'} style={{}} />
                        <div className='flex flex-col p-2'>
                          <span className='text-sm font-bold'>{ele.subtitle}</span>
                          <p className='text-center text-[#038967] font-bold mb-0 text-xl'>{`${ele.value} ${ele.unit}`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      }
      {
        tab === 2 &&
        <div className='w-full mx-auto'>
          <div className='grid grid-cols-10 gap-2'>
            <div className='col-span-10 md:col-span-4'>
              <div className='w-full rounded-md shadow-md bg-white py-4 my-2'>
                <div className='flex flex-col text-center'>
                  <span className='text-xl font-bold'>อัตราส่วนครูต่อนักเรียน</span>
                  <span className='text-[#003171] text-2xl font-bold'>{`1 : ${Math.ceil(data.total / data.teacher_total)}`}</span>
                </div>
              </div>
              <div className='w-full rounded-md shadow-md bg-white py-4 my-2'>
                <div className='flex flex-col text-center'>
                  <span className='text-xl font-bold'>จำนวนครูและบุคลากรทั้งหมด</span>
                  <span className='text-[#048967] text-2xl font-bold'>{data.teacher_total.toLocaleString('en-Us')}</span>
                  <span>คน</span>
                </div>
              </div>
              <div className='w-full rounded-md shadow-md bg-white py-8 my-2'>
                <div className='flex flex-col'>
                  <div className='flex flex-row mx-auto'>
                    <ComputerIcon className='my-auto' fontSize="large" />
                    <p className='ml-2 my-auto text-2xl font-bold'>คอมพิวเตอร์</p>
                  </div>
                  <div className='text-center mt-4'>
                    <p className='text-xl font-bold mb-1'>จำนวนคอมพิวเตอร์ทั้งหมด</p>
                    <p>จำนวน<span className='text-2xl text-[#003F88] font-bold mx-2'>{(data.pc + data.notebook).toLocaleString('en-Us')}</span>เครื่อง</p>
                  </div>
                  <div className='text-center mt-4'>
                    <p className='text-xl font-bold mb-1'>คอมพิวเตอร์ตั้งโต๊ะ</p>
                    <p>จำนวน<span className='text-2xl text-[#003F88] font-bold mx-2'>{data.pc.toLocaleString('en-Us')}</span>เครื่อง</p>
                  </div>
                  <div className='text-center mt-4'>
                    <p className='text-xl font-bold mb-1'>Notebook</p>
                    <p>จำนวน<span className='text-2xl text-[#003F88] font-bold mx-2'>{data.notebook.toLocaleString('en-Us')}</span>เครื่อง</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-10 md:col-span-3'>
              <div className='w-full rounded-md shadow-md bg-white flex flex-col py-4 my-2'>
                <div className='flex flex-row ml-2'>
                  <BoltIcon className='my-auto text-[#fbbf24]' />
                  <p className='ml-1 my-auto text-lg font-bold'>มีไฟฟ้าใช้</p>
                </div>
                <Chart
                  options={{
                    title: `${((data.school_has_electricity / (data.sum_school_sizeS + data.sum_school_sizeX + data.sum_school_sizeL + data.sum_school_sizeXL)) * 100).toFixed(2)} %`,
                    type: 'donut',
                  }}
                  containerProps={{ style: { height: "250px" } }}
                  style={{ width: '150px', height: '260px' }}
                  data={[{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                      {
                        name: 'มีไฟฟ้าใช้',
                        color: '#047857',
                        y: data.school_has_electricity,
                      },
                      {
                        name: '',
                        color: '#d4d4d8',
                        y: (data.sum_school_sizeS + data.sum_school_sizeX + data.sum_school_sizeL + data.sum_school_sizeXL) - data.school_has_electricity,
                      }
                    ]
                  }]}
                />
                <p className='text-center text-lg m-0'>จำนวน<span className='text-2xl font-bold mx-2'>{data.school_has_electricity.toLocaleString('en-Us')}</span>โรงเรียน</p>
              </div>
              <div className='w-full rounded-md shadow-md bg-white flex flex-col py-4 my-2'>
                <div className='flex flex-row ml-2'>
                  <FactoryIcon className='my-auto text-[#16a34a]' />
                  <p className='ml-1 my-auto text-lg font-bold'>อยู่ในเขตบริการไฟฟ้า</p>
                </div>
                <Chart
                  options={{
                    title: `${((data.school_has_electricity_service_area / (data.sum_school_sizeS + data.sum_school_sizeX + data.sum_school_sizeL + data.sum_school_sizeXL)) * 100).toFixed(2)} %`,
                    type: 'donut',
                  }}
                  containerProps={{ style: { height: "250px" } }}
                  style={{ width: '150px', height: '260px' }}
                  data={[{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                      {
                        name: 'อยู่ในเขตบริการไฟฟ้า',
                        color: '#047857',
                        y: data.school_has_electricity_service_area,
                      },
                      {
                        name: '',
                        color: '#d4d4d8',
                        y: (data.sum_school_sizeS + data.sum_school_sizeX + data.sum_school_sizeL + data.sum_school_sizeXL) - data.school_has_electricity_service_area,
                      }
                    ]
                  }]}
                />
                <p className='text-center text-lg m-0'>จำนวน<span className='text-2xl font-bold mx-2'>{data.school_has_electricity_service_area.toLocaleString('en-Us')}</span>โรงเรียน</p>
                <p className='text-center text-sm m-0'>เขตบริการไฟฟ้าส่วนภูมิภาค/นครหลวง</p>
              </div>
            </div>
            <div className='col-span-10 md:col-span-3'>
              <div className='w-full rounded-md shadow-md bg-white flex flex-col py-4 my-2'>
                <div className='flex flex-row ml-2'>
                  <BoltIcon className='my-auto text-[#ef4444]' />
                  <p className='ml-1 my-auto text-lg font-bold'>ไม่มีไฟฟ้าใช้</p>
                </div>
                <Chart
                  options={{
                    title: `${((data.school_not_electricity / (data.sum_school_sizeS + data.sum_school_sizeX + data.sum_school_sizeL + data.sum_school_sizeXL)) * 100).toFixed(2)} %`,
                    type: 'donut',
                  }}
                  containerProps={{ style: { height: "250px" } }}
                  style={{ width: '150px', height: '260px' }}
                  data={[{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                      {
                        name: 'ไม่มีไฟฟ้าใช้',
                        color: '#ef4444',
                        y: data.school_not_electricity,
                      },
                      {
                        name: '',
                        color: '#d4d4d8',
                        y: (data.sum_school_sizeS + data.sum_school_sizeX + data.sum_school_sizeL + data.sum_school_sizeXL) - data.school_not_electricity,
                      }
                    ]
                  }]}
                />
                <p className='text-center text-lg m-0'>จำนวน<span className='text-2xl font-bold mx-2'>{data.school_not_electricity.toLocaleString('en-Us')}</span>โรงเรียน</p>
              </div>
              <div className='w-full rounded-md shadow-md bg-white flex flex-col py-4 my-2'>
                <div className='flex flex-row ml-2'>
                  <FactoryIcon className='my-auto text-[#b91c1c]' />
                  <p className='ml-1 my-auto text-lg font-bold'>อยู่นอกเขตบริการไฟฟ้า</p>
                </div>
                <Chart
                  options={{
                    title: `${((data.school_not_electricity_service_area / (data.sum_school_sizeS + data.sum_school_sizeX + data.sum_school_sizeL + data.sum_school_sizeXL)) * 100).toFixed(2)} %`,
                    type: 'donut',
                  }}
                  containerProps={{ style: { height: "250px" } }}
                  style={{ width: '150px', height: '260px' }}
                  data={[{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                      {
                        name: '1',
                        color: '#ef4444',
                        y: data.school_not_electricity_service_area,
                      },
                      {
                        name: '2',
                        color: '#d4d4d8',
                        y: (data.sum_school_sizeS + data.sum_school_sizeX + data.sum_school_sizeL + data.sum_school_sizeXL) - data.school_not_electricity_service_area,
                      }
                    ]
                  }]}
                />
                <p className='text-center text-lg m-0'>จำนวน<span className='text-2xl font-bold mx-2'>{data.school_not_electricity_service_area.toLocaleString('en-Us')}</span>โรงเรียน</p>
                <p className='text-center text-sm m-0'>เขตบริการไฟฟ้าส่วนภูมิภาค/นครหลวง</p>
              </div>
            </div>
          </div>
          <div className='w-full rounded-md shadow-md bg-white my-2 py-4 px-4'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
              <div className='col-span-1 my-auto'>
                <p className='text-xl font-bold text-center'>ข้อมูลอินเตอร์เน็ต</p>
              </div>
              <div className='col-span-1 text-center'>
                <span className='flex my-auto justify-center'><WifiIcon className='my-auto text-[#16a34a]' fontSize="large" /><span className='text-xl ml-2 my-auto'>มีเข้าถึงอินเตอร์เน็ต</span></span>
                <span className='text-2xl text-[#003F88] font-bold mr-2'>{data.school_has_internet.toLocaleString('en-Us')}</span> โรงเรียน
              </div>
              <div className='col-span-1 text-center'>
                <span className='flex my-auto justify-center'><WifiOffOutlinedIcon className='my-auto text-[#ef4444]' fontSize="large" /><span className='text-xl ml-2 my-auto'>ไม่มีการเข้าถึงอินเตอร์เน็ต</span></span>
                <span className='text-2xl text-[#003F88] font-bold mr-2'>{data.school_not_internet.toLocaleString('en-Us')}</span> โรงเรียน
              </div>
            </div>
          </div>
          <div className='w-full rounded-md shadow-md bg-white my-2 py-4 px-4'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
              <div className='col-span-1 my-auto'>
                <p className='text-xl font-bold text-center'>ข้อมูลแหล่งน้ำใช้</p>
              </div>
              <div className='col-span-1 text-center'>
                <span className='flex my-auto justify-center'><OpacityIcon className='my-auto text-[#16a34a]' fontSize="large" /><span className='text-xl ml-2 my-auto'>มีน้ำใช้</span></span>
                <span className='text-2xl text-[#003F88] font-bold mr-2'>{data.school_has_water.toLocaleString('en-Us')}</span> โรงเรียน
              </div>
              <div className='col-span-1 text-center'>
                <span className='flex my-auto justify-center'><FormatColorResetIcon className='my-auto text-[#ef4444]' fontSize="large" /><span className='text-xl ml-2 my-auto'>ไม่มีน้ำใช้</span></span>
                <span className='text-2xl text-[#003F88] font-bold mr-2'>{data.school_not_water.toLocaleString('en-Us')}</span> โรงเรียน
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
            <div className='col-span-1'>
              <div className='w-full rounded-md shadow-md bg-white justify-between'>
                <ProgressProbView
                  agricultural={data.agricultural_chemistry_air_problem}
                  chemical={data.factory_air_problem}
                  traffic={data.traffic_air_problem}
                  sewage={data.garbage_air_problem}
                  total={
                    data.agricultural_chemistry_air_problem + data.factory_air_problem + data.traffic_air_problem + data.garbage_air_problem
                  }
                  category="อากาศ"
                  title="ปัญหาสภาพอากาศ"
                />
              </div>
            </div>
            <div className='col-span-1'>
              <div className='w-full rounded-md shadow-md bg-white justify-between'>
                <ProgressProbView
                  agricultural={data.agricultural_chemistry_noise_problem}
                  chemical={data.factory_noise_problem}
                  traffic={data.traffic_noise_problem}
                  sewage={data.garbage_noise_problem}
                  total={
                    data.agricultural_chemistry_noise_problem + data.factory_noise_problem + data.traffic_noise_problem + data.garbage_noise_problem
                  }
                  category="น้ำ"
                  title="ปัญหาสภาพน้ำ"
                />
              </div>
            </div>
            <div className='col-span-1'>
              <div className='w-full rounded-md shadow-md bg-white justify-between'>
                <ProgressProbView
                  agricultural={data.agricultural_chemistry_water_problem}
                  chemical={data.factory_water_problem}
                  traffic={data.traffic_water_problem}
                  sewage={data.garbage_water_problem}
                  total={
                    data.agricultural_chemistry_water_problem + data.factory_water_problem + data.traffic_water_problem + data.garbage_water_problem
                  }
                  category="เสียง"
                  title="ปัญหาด้านเสียง"
                />
              </div>
            </div>
          </div>
        </div>
      }
      <a onClick={() => { setModalRef(true) }} className="font-[22px] mb-6 mt-6 text-gray-800 underline-offset-auto ">
        <InfoIcon /> แหล่งที่มาของข้อมูล
      </a>
      <Modal
        width={800}
        visible={ismodalref}
        // title="แหล่งที่มาของข้อมูล"
        onOk={() => setModalRef(false)}
        onCancel={() => setModalRef(false)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div>
          <div>
              <span className='text-lg font-bold'>แหล่งที่มาข้อมูล</span><br />
              <span className='pl-6'>ข้อมูลการช่วยเหลือกลุ่มเป้าหมายในแต่ละโครงการของ กสศ. ประกอบด้วย</span><br />
              <br />
              <span className='text-lg font-bold'>ข้อมูลนักเรียนในระบบการศึกษา</span><br />
              <span className='pl-6'>ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2566 </span><br />
              <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                  <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                      <ul className='bg-[#f0fdf4] rounded-md py-4'>
                          <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                          <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                          <li className='pl-6'>- กรมส่งเสริมการเรียนรู้</li>
                          <li className='pl-6'>- กองทัพบก</li>
                          <li className='pl-6'>- กองทัพเรือ</li>
                          <li className='pl-6'>- กองทัพอากาศ</li>
                          <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                          <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ </li>
                          <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์ </li>
                          <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์ </li>
                          <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                          <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                          <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                          <li className='pl-6'>- สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                          <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                      </ul>
                  </Panel>
              </Collapse>
              <br />
              <span className='text-lg font-bold'>ข้อมูลจำนวนเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (อายุ 3 - 17 ปี)</span><br />
              <span className='pl-6'>(1) ข้อมูลทะเบียนราษฎร์ ปี 2566 </span><br />
              <span className='pl-6'>(2) ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2566</span><br />
              <span className='pl-6'>(3) นำข้อมูลในข้อที่ (1) หักลบกับ (2) จะได้จำนวนเด็กที่ไม่มีข้อมูลในระบบการศึกษา ปี 2564</span><br />
              <br />
              <span className='text-lg font-bold'>ข้อมูลจำนวนสถานศึกษา</span><br />
              <span className='pl-6'>ข้อมูลจำนวนสถานศึกษาในแต่ละสังกัดปีการศึกษา 2566 </span><br />
              <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                  <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                      <ul className='bg-[#f0fdf4] rounded-md py-4'>
                          <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                          <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                          <li className='pl-6'>- กรมส่งเสริมการเรียนรู้</li>
                          <li className='pl-6'>- กองทัพบก</li>
                          <li className='pl-6'>- กองทัพเรือ</li>
                          <li className='pl-6'>- กองทัพอากาศ</li>
                          <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                          <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ </li>
                          <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์ </li>
                          <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์ </li>
                          <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                          <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                          <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                          <li className='pl-6'>- สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                          <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                      </ul>
                  </Panel>
              </Collapse>
              <br />
              <span className='text-lg font-bold'>ทุนสร้างโอกาส</span><br />
              <span className='pl-6'><b>ทุนเสมอภาค</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือใน 6 สังกัด ได้แก่ สพฐ. อปท. ตชด. พศ. สช. และ กทม. ปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนครูรัก(ษ์)ถิ่น</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนนวัตกรรมสายอาชีพ</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนพระกนิษฐา</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน</b> ข้อมูลจำนวนกลุ่มเป้าหมายที่ได้รับความช่วยเหลือในปีการศึกษา 2564</span>
              <br />
              <span className='text-lg font-bold'>นวัตกรรมต้นแบบ</span><br />
              <span className='pl-6'><b>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา </b> ข้อมูลการสำรวจแผนงาน 20 จังหวัดเสมอภาค ปี 2563</span><br />
              <span className='pl-6'><b>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา</b> ข้อมูลการสำรวจแผนงานครูและเด็กนอกระบบ ปี 2563 </span><br />
              <span className='pl-6'><b>เด็กปฐมวัยยากจนใน ศพด.</b> ข้อมูลจำนวนเด็กปฐมวัยที่ได้รับความช่วยเหลือในพื้นที่ 20 จังหวัดเสมอภาค 	ในปีการศึกษา 2563 </span><br />
              <span className='pl-6'><b>โครงการ TSQP </b> ข้อมูลจำนวนโรงเรียนที่เข้าร่วมโครงการ ปีการศึกษา 2564 </span><br />
              <br />
              <span className='text-lg font-bold text-[#15803d]'>*ประมวลผล ณ เดือนสิงหาคม 2567</span><br />
          </div>
        </div>
      </Modal>
    </div >
  )
}

export default Overview
