import React, { useState, useEffect } from 'react';
import {
  convertProvinceTextToId,
  convertProvinceIdToText
} from '../../../mapbox/mapboxfn'
import { Select, Collapse, Modal } from 'antd';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';

const { Option } = Select;
const { Panel } = Collapse;

const MapAreaView = (props) => {
  let { filter, setFilter } = props
  const [data, setData] = useState(props.data);
  const [ismodalref, setModalRef] = useState(false)

  const Card = ({ title, value, unit }) => {
    return <div className='w-full text-center rounded-md shadow-md bg-white px-4 py-4'>
      <div className='h-full my-4'>
        <h3 className='text-lg xl:text-xl m-0'>{title}</h3>
        <h1 className='font-bold text-2xl xl:text-3xl m-0'>{value}</h1>
        {unit && <h3 className='font-bold text-lg xl:text-xl m-0'>{unit}</h3>}
      </div>
    </div>
  }

  const _mapData = (_data) => {
    return _data.filter(item => filter.tumbon_select.includes(item.tumbon_name))
  }

  return (
    <div>
      <div className='grid grid-cols-6'>
        {/* {
          (filter.user_role.includes('eef') || filter.user_role.includes('admin')) &&
          <div className='col-span-6 md:col-span-3 flex flex-row'>
            <h5 className="text-base mx-auto my-auto font-semibold">เลือกจังหวัด: </h5>
            <Select
              className='new-design-select'
              showSearch
              placeholder="เลือกจังหวัด"
              maxTagCount='responsive'
              value={convertProvinceIdToText(filter.user_province) + " " + filter.user_zone}
              style={{ width: '70%', margin: 4 }}
              onChange={(val) => {
                setFilter({
                  ...filter,
                  user_province: convertProvinceTextToId(val.split("_")[0]),
                  user_zone: val.split("_")[1],
                })
              }}
            >
              {
                filter.list_province.map(ele => {
                  return <Option value={ele.province + "_" + ele.zone}>{ele.province + " " + ele.zone}</Option>
                })
              }
            </Select>
          </div>
        } */}
        <div className='col-span-6 md:col-span-3 flex flex-row'>
          <h5 className="text-base mx-auto my-auto font-semibold">เลือกอำเภอ: </h5>
          <Select
            mode="multiple"
            className='new-design-select'
            showSearch
            placeholder="เลือกอำเภอ"
            maxTagCount='responsive'
            value={filter.city_select}
            style={{ width: '70%', margin: 4 }}
            onChange={(val) => {
              let _tumbon = [...new Set(data.tumbon.filter(item => val.includes(item.city_name)).map(item => item.tumbon_name))]
              let _school = filter.school_address.filter(item => val.includes(item.city_name)).map(item => item.hostname)
              setFilter({ ...filter, city_select: val, tumbon_select: _tumbon, school_select: _school })
            }}
          >
            {
              filter.list_city.map(ele => {
                return <Option value={ele}>{ele}</Option>
              })
            }
          </Select>
        </div>
        <div className='col-span-6 md:col-span-3 flex flex-row'>
          <h5 className="text-base mx-auto my-auto font-semibold">เลือกตำบล: </h5>
          <Select
            mode="multiple"
            className='new-design-select'
            showSearch
            placeholder="เลือกตำบล"
            maxTagCount='responsive'
            value={filter.tumbon_select}
            style={{ width: '70%', margin: 4 }}
            onChange={(val) => {
              let _city = [...new Set(data.tumbon.filter(item => val.includes(item.tumbon_name)).map(item => item.city_name))]
              let _school = filter.school_address.filter(item => val.includes(item.tumbon_name)).map(item => item.hostname)
              setFilter({ ...filter, city_select: _city, tumbon_select: val, school_select: _school })
            }}
          >
            {
              filter.list_tumbon.map(ele => {
                return <Option value={ele}>{ele}</Option>
              })
            }
          </Select>
        </div>
        <div className={`${(filter.user_role.includes('eef') || filter.user_role.includes('admin')) ? "col-span-6 md:col-span-3" : "col-span-6 md:col-span-3 md:col-start-2"} flex flex-row`}>
          <h5 className="text-base mx-auto my-auto font-semibold">เลือกโรงเรียน: </h5>
          <Select
            mode="multiple"
            className='new-design-select'
            showSearch
            placeholder="เลือกโรงเรียน"
            style={{ width: '70%', margin: 4 }}
            maxTagCount='responsive'
            value={filter.school_select}
            onChange={(val) => {
              setFilter({ ...filter, school_select: val })
            }}
          >
            {
              filter.list_school.map(ele => {
                return <Option value={ele}>{ele}</Option>
              })
            }
          </Select>
        </div>
        <div className={`${(filter.user_role.includes('eef') || filter.user_role.includes('admin')) ? "col-span-6 md:col-span-6 justify-center" : "md:col-span-1"} flex flex-row text-center`}>
          <Button size="small" variant="outlined" startIcon={<RefreshIcon />} onClick={() => {
            setFilter({
              ...filter,
              city_select: filter.list_city, tumbon_select: filter.list_tumbon, school_select: filter.list_school
            })
          }}>
            คืนค่า Filter
          </Button>
        </div>
      </div>
      <h2 className='text-center font-bold text-lg xl:text-xl mt-8'>
        จำนวนเด็กและเยาวชนวัยเรียน
      </h2>
      <h1 className='text-center font-bold text-2xl xl:text-3xl text-orange-600'>
        {`" ${filter.province_name} เขต ${filter.zone} "`}
      </h1>
      <h1 className='text-center font-bold text-3xl xl:text-4xl text-blue-700'>
        {`${(_mapData(data.tumbon).reduce((acc, cur) => acc + (cur.total + cur.total_oosc), 0)).toLocaleString('en-Us')} คน`}
      </h1>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
        <div className='col-span-1'>
          <Card title={<span>จำนวนนักเรียนทั้งหมด</span>} value={<span className='text-[#048967]'>{_mapData(data.tumbon).reduce((acc, cur) => acc + (cur.total), 0).toLocaleString('en-Us')}</span>} unit={'คน'} />
        </div>
        <div className='col-span-1'>
          <Card title={<span>จำนวนครูทั้งหมด</span>} value={<span className='text-[#1611FF]'>{_mapData(data.tumbon).reduce((acc, cur) => acc + (cur.teacher_total), 0).toLocaleString('en-Us')}</span>} unit={'คน'} />
        </div>
        <div className='col-span-1'>
          <Card title={<span>อัตราส่วนครูต่อนักเรียน</span>} value={<span className='text-[#048967]'>{
            (Math.ceil(_mapData(data.tumbon).reduce((acc, cur) => acc + (cur.total), 0) / _mapData(data.tumbon).reduce((acc, cur) => acc + (cur.teacher_total), 0)))
          }</span>} unit={'คน'} />
        </div>
      </div>
      <div className='w-full rounded-md shadow-md bg-white px-4 py-4 mt-4'>
        <h1 className='text-center text-2xl font-bold'>การช่วยเหลือนักเรียนและโรงเรียนของ กสศ.ในพื้นที่ของท่าน</h1>
        {/* <div className='flex justify-between mt-4'>
          <p className='text-lg font-bold text-left'>โรงเรียนท่าทอง - เมืองพิษณุโลก</p>
          <p className='text-lg font-bold text-right'>60 คน</p>
        </div> */}
        <div className='border-dashed border-4 border-stone-300 py-4 mt-4'>
          <p className='flex justify-between ml-6 mr-4'>
            <span className='text-left text-xl font-bold'>ทุนสร้างโอกาส</span>
            <span className='text-right'>
              <span className='text-lg md:text-2xl font-bold'>{_mapData(data.tumbon).reduce((acc, cur) => acc + (cur.cct + cur.vec + cur.krt + cur.tps + cur.target_group), 0)}</span>
              <span className='pl-2 text-xl font-bold'>คน</span>
            </span>
          </p>
          {
            [
              { title: 'ทุนเสมอภาค', value: _mapData(data.tumbon).reduce((acc, cur) => acc + (cur.cct), 0), unit: 'คน' },
              { title: 'ทุนครูรัก(ษ์)ถิ่น', value: _mapData(data.tumbon).reduce((acc, cur) => acc + (cur.krt), 0), unit: 'คน' },
              { title: 'ทุนนวัตกรรม', value: _mapData(data.tumbon).reduce((acc, cur) => acc + (cur.vec), 0), unit: 'คน' },
              { title: 'ทุนพระกนิษฐา', value: _mapData(data.tumbon).reduce((acc, cur) => acc + (cur.tps), 0), unit: 'คน' },
              { title: 'ทุนพัฒนาอาชีพชุมชนเป็นฐาน', value: _mapData(data.tumbon).reduce((acc, cur) => acc + (cur.target_group), 0), unit: 'คน' },
            ].map((ele, idx) => {
              return (
                <div key={ele.title + '_' + idx} className='flex justify-between my-1 ml-8 md:ml-16 mr-4'>
                  <span className='text-left text-base md:text-lg'>{ele.title}</span>
                  <span className='text-right'>
                    <span className='text-lg md:text-2xl'>{ele.value?.toLocaleString("en-US")}</span>
                    <span className='pl-2 text-xl'>{ele.unit}</span>
                  </span>
                </div>
              )
            })
          }
        </div>
        <div className='border-dashed border-4 border-stone-300 py-4 mt-4'>
          <p className='flex justify-between ml-6 mr-4'>
            <span className='text-left text-xl font-bold'>นวัตกรรมต้นแบบ</span>
            <span className='text-right'>
              <span className='text-lg md:text-2xl font-bold'>{_mapData(data.tumbon).reduce((acc, cur) => acc + (cur.tsqp), 0)}</span>
              <span className='pl-2 text-xl font-bold'>โรงเรียน</span>
            </span>
          </p>
          {
            [
              // { title: 'เด็กที่ไม่มีข้อมูลในระบบการศึกษา (ABE)', value: _mapData(data.tumbon).reduce((acc, cur) => acc + (cur.oosc), 0), unit: 'คน' },
              // { title: 'เด็กปฐมวัย', value: _mapData(data.tumbon).reduce((acc, cur) => acc + (cur.abe), 0), unit: 'คน' },
              { title: 'TSQP', value: _mapData(data.tumbon).reduce((acc, cur) => acc + (cur.tsqp), 0), unit: 'โรงเรียน' },
            ].map((ele, idx) => {
              return (
                <div key={ele.title + '_' + idx} className='flex justify-between my-1 ml-8 md:ml-16 mr-4'>
                  <span className='text-left text-base md:text-lg'>{ele.title}</span>
                  <span className='text-right'>
                    <span className='text-lg md:text-2xl'>{ele.value?.toLocaleString("en-US")}</span>
                    <span className='pl-2 text-xl'>{ele.unit}</span>
                  </span>
                </div>
              )
            })
          }
        </div>
      </div>
      <a onClick={() => { setModalRef(true) }} className="font-[22px] mb-6 mt-6 text-gray-800 underline-offset-auto ">
        <InfoIcon /> แหล่งที่มาของข้อมูล
      </a>
      <Modal
        width={800}
        visible={ismodalref}
        // title="แหล่งที่มาของข้อมูล"
        onOk={() => setModalRef(false)}
        onCancel={() => setModalRef(false)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div>
          <div>
              <span className='text-lg font-bold'>แหล่งที่มาข้อมูล</span><br />
              <span className='pl-6'>ข้อมูลการช่วยเหลือกลุ่มเป้าหมายในแต่ละโครงการของ กสศ. ประกอบด้วย</span><br />
              <br />
              <span className='text-lg font-bold'>ข้อมูลนักเรียนในระบบการศึกษา</span><br />
              <span className='pl-6'>ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2566 </span><br />
              <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                  <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                      <ul className='bg-[#f0fdf4] rounded-md py-4'>
                          <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                          <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                          <li className='pl-6'>- กรมส่งเสริมการเรียนรู้</li>
                          <li className='pl-6'>- กองทัพบก</li>
                          <li className='pl-6'>- กองทัพเรือ</li>
                          <li className='pl-6'>- กองทัพอากาศ</li>
                          <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                          <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ </li>
                          <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์ </li>
                          <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์ </li>
                          <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                          <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                          <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                          <li className='pl-6'>- สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                          <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                      </ul>
                  </Panel>
              </Collapse>
              <br />
              <span className='text-lg font-bold'>ข้อมูลจำนวนเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (อายุ 3 - 17 ปี)</span><br />
              <span className='pl-6'>(1) ข้อมูลทะเบียนราษฎร์ ปี 2566 </span><br />
              <span className='pl-6'>(2) ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2566</span><br />
              <span className='pl-6'>(3) นำข้อมูลในข้อที่ (1) หักลบกับ (2) จะได้จำนวนเด็กที่ไม่มีข้อมูลในระบบการศึกษา ปี 2564</span><br />
              <br />
              <span className='text-lg font-bold'>ข้อมูลจำนวนสถานศึกษา</span><br />
              <span className='pl-6'>ข้อมูลจำนวนสถานศึกษาในแต่ละสังกัดปีการศึกษา 2566 </span><br />
              <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                  <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                      <ul className='bg-[#f0fdf4] rounded-md py-4'>
                          <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                          <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                          <li className='pl-6'>- กรมส่งเสริมการเรียนรู้</li>
                          <li className='pl-6'>- กองทัพบก</li>
                          <li className='pl-6'>- กองทัพเรือ</li>
                          <li className='pl-6'>- กองทัพอากาศ</li>
                          <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                          <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ </li>
                          <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์ </li>
                          <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์ </li>
                          <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                          <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                          <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                          <li className='pl-6'>- สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                          <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                      </ul>
                  </Panel>
              </Collapse>
              <br />
              <span className='text-lg font-bold'>ทุนสร้างโอกาส</span><br />
              <span className='pl-6'><b>ทุนเสมอภาค</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือใน 6 สังกัด ได้แก่ สพฐ. อปท. ตชด. พศ. สช. และ กทม. ปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนครูรัก(ษ์)ถิ่น</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนนวัตกรรมสายอาชีพ</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนพระกนิษฐา</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน</b> ข้อมูลจำนวนกลุ่มเป้าหมายที่ได้รับความช่วยเหลือในปีการศึกษา 2564</span>
              <br />
              <span className='text-lg font-bold'>นวัตกรรมต้นแบบ</span><br />
              <span className='pl-6'><b>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา </b> ข้อมูลการสำรวจแผนงาน 20 จังหวัดเสมอภาค ปี 2563</span><br />
              <span className='pl-6'><b>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา</b> ข้อมูลการสำรวจแผนงานครูและเด็กนอกระบบ ปี 2563 </span><br />
              <span className='pl-6'><b>เด็กปฐมวัยยากจนใน ศพด.</b> ข้อมูลจำนวนเด็กปฐมวัยที่ได้รับความช่วยเหลือในพื้นที่ 20 จังหวัดเสมอภาค 	ในปีการศึกษา 2563 </span><br />
              <span className='pl-6'><b>โครงการ TSQP </b> ข้อมูลจำนวนโรงเรียนที่เข้าร่วมโครงการ ปีการศึกษา 2564 </span><br />
              <br />
              <span className='text-lg font-bold text-[#15803d]'>*ประมวลผล ณ เดือนสิงหาคม 2567</span><br />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MapAreaView
