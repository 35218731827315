import React, { useState, useEffect } from 'react';
import {
  convertProvinceTextToId,
  convertProvinceIdToText
} from '../../../mapbox/mapboxfn'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Select, Collapse, Modal } from 'antd';

import InfoIcon from '@mui/icons-material/Info';

const { Option } = Select;
const { Panel } = Collapse;

const MapAreaView = (props) => {
  let { filter, setFilter, data } = props
  const [ismodalref, setModalRef] = useState(false)
  const [tabindex, setTabIndex] = useState(0)
  const [tumbon_view_filter, setTumbonViewFilter] = useState({
    replace: "ตำบล",
    year: "2566",
    value: [null, null, null],
    index: null,
  })
  const [school_view_filter, setSchoolViewFilter] = useState({
    replace: "โรงเรียน",
    year: "2566",
    value: [null, null, null],
    index: null,
  })

  const [result, setResult] = useState({
    total_fund: { text: "", value: [0, 0, 0], unit: 'คน' },
    total_inno: { text: "", value: [0, 0, 0], unit: 'คน' },
    total_research: { text: "", value: [0, 0, 0], unit: 'คน' },
    total: { text: "", value: [0, 0, 0], unit: 'คน' },
    total_school: { text: "", value: [0, 0, 0], unit: 'โรงเรียน' },
    cct: { text: "ทุนเสมอภาค", value: [0, 0, 0], unit: 'คน' },
    krt: { text: "ทุนครูรัก(ษ์)ถิ่น", value: [0, 0, 0], unit: 'คน' },
    vec: { text: "ทุนนวัตกรรมสายอาชีพชั้นสูง", value: [0, 0, 0], unit: 'คน' },
    tps: { text: "ทุนพระกนิษฐาสัมมาชีพ", value: [0, 0, 0], unit: 'คน' },
    target_group: { text: "ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน", value: [0, 0, 0], unit: 'คน' },
    tsqp: { text: "โครงการโรงเรียนพัฒนาตนเอง", value: [0, 0, 0], unit: 'โรงเรียน' },
    abe: { text: "การจัดการเชิงพื้นที่", value: [0, 0, 0], unit: 'คน' },
    fsql: { text: "โรงเรียนในโครงการ FSQL", value: [0, 0, 0], unit: 'โรงเรียน' },
    pisa: { text: "PISA for school (นักเรียนช้างเผือก)", value: [0, 0, 0], unit: 'คน' },
  })

  useEffect(() => {
    if (tabindex === 0) {
      let p = tumbon_view_filter
      p.value.map((id, index) => {
        if (id) {
          // console.log(data.tumbon.filter(item => item.tumbon_name === id))
          result.cct.value[index] = data.tumbon.filter(item => item.tumbon_name === id)[0].cct
          result.krt.value[index] = data.tumbon.filter(item => item.tumbon_name === id)[0].krt
          result.vec.value[index] = data.tumbon.filter(item => item.tumbon_name === id)[0].vec
          result.tps.value[index] = data.tumbon.filter(item => item.tumbon_name === id)[0].tps
          result.target_group.value[index] = data.tumbon.filter(item => item.tumbon_name === id)[0].target_group
          result.tsqp.value[index] = data.tumbon.filter(item => item.tumbon_name === id)[0].tsqp
          result.abe.value[index] = data.tumbon.filter(item => item.tumbon_name === id)[0].abe + data.tumbon.filter(item => item.tumbon_name === id)[0].ecd
          result.fsql.value[index] = 0
          result.pisa.value[index] = 0

          result.total_fund.value[index] = result.cct.value[index] +
            result.krt.value[index] +
            result.vec.value[index] +
            result.tps.value[index] +
            result.target_group.value[index]

          result.total_inno.value[index] = result.abe.value[index]

          result.total_research.value[index] = result.pisa.value[index]

          result.total.value[index] = result.cct.value[index] +
            result.krt.value[index] +
            result.vec.value[index] +
            result.tps.value[index] +
            result.target_group.value[index] +
            result.abe.value[index] +
            result.pisa.value[index]

          result.total_school.value[index] = result.tsqp.value[index] 
            // + result.fsql.value[index]
        }
      })
    } else {
      let p = school_view_filter
      p.value.map((id, index) => {
        if (id) {
          result.cct.value[index] = data.listschool.filter(item => item.hostname === id)[0].exjon
          result.krt.value[index] = data.listschool.filter(item => item.hostname === id)[0].krt
          result.vec.value[index] = data.listschool.filter(item => item.hostname === id)[0].vec
          result.tps.value[index] = data.listschool.filter(item => item.hostname === id)[0].tps
          result.target_group.value[index] = data.listschool.filter(item => item.hostname === id)[0].target_group
          result.abe.value[index] = data.listschool.filter(item => item.hostname === id)[0].abe
          result.fsql.value[index] = 0
          result.pisa.value[index] = 0

          result.total_fund.value[index] = result.cct.value[index] +
            result.krt.value[index] +
            result.vec.value[index] +
            result.tps.value[index] +
            result.target_group.value[index]

          result.total_inno.value[index] = result.abe.value[index]

          result.total_research.value[index] = result.pisa.value[index]

          result.total.value[index] = result.cct.value[index] +
            result.krt.value[index] +
            result.vec.value[index] +
            result.tps.value[index] +
            result.target_group.value[index] +
            result.abe.value[index] +
            result.pisa.value[index]

          result.total_school.value[index] = result.tsqp.value[index] 
            // + result.fsql.value[index]
        }
      })
    }
    setResult({ ...result })
  }, [tumbon_view_filter, school_view_filter, tabindex])

  const MainCard = ({ student, school, unit_student, unit_school }) => {
    return <div className='w-full xl:bg-white xl:shadow-lg xl:shadow-emerald-500/40 xl:rounded-xl text-center p-2'>
      <div className='my-12'>
        <h3 className='text-lg lg:text-xl mb-0'>จำนวนเด็กที่ได้รับทุน</h3>
        <h1 className='text-xl lg:text-3xl font-bold text-[#144174] mb-0'>{student ? student.toLocaleString("en-US") : "-"}</h1>
        <h3 className='text-lg lg:text-xl text-[#144174] mb-0'>{unit_student}</h3>
        <div className='border-dotted border-b border-neutral-400 w-3/4 mx-auto my-6'></div>
        <h3 className='text-lg lg:text-xl mb-0'>จำนวนโรงเรียน</h3>
        <h1 className='text-xl lg:text-3xl font-bold text-[#068769] mb-0'>{school ? school.toLocaleString("en-US") : "-"}</h1>
        <h3 className='text-lg lg:text-xl text-[#068769] mb-0'>{unit_school}</h3>
      </div>
    </div>
  }

  const RowCell = ({ text, value, unit }) => {
    return (
      <div className='grid grid-cols-3 lg:grid-cols-4 my-4 lg:my-1'>
        <div className='col-span-3 col-start-1 lg:col-end-2'>
          <h2 className='text-xl text-center lg:text-left'>{text}</h2>
        </div>
        <div className='col-span-1 lg:col-start-2 lg:col-end-3 text-center'>
          <h2 className='text-xl'>{value[0] ? `${value[0].toLocaleString("en-US")} ${unit}` : "-"}</h2>
        </div>
        <div className='col-span-1 lg:col-start-3 lg:col-end-4 text-center'>
          <h2 className='text-xl'>{value[1] ? `${value[1].toLocaleString("en-US")} ${unit}` : "-"}</h2>
        </div>
        <div className='col-span-1 lg:col-start-4 lg:col-end-5 text-center'>
          <h2 className='text-xl'>{value[2] ? `${value[2].toLocaleString("en-US")} ${unit}` : "-"}</h2>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='w-full mx-auto'>
        {/* {
          (filter.user_role.includes('eef') || filter.user_role.includes('admin')) && <div className='grid grid-cols-9'>
            <div className='col-span-9 md:col-span-3 md:col-start-4 flex flex-row mb-2'>
              <h5 className="text-base mx-auto my-auto font-semibold">เลือกจังหวัด: </h5>
              <Select
                className='new-design-select'
                showSearch
                placeholder="เลือกจังหวัด"
                maxTagCount='responsive'
                value={convertProvinceIdToText(filter.user_province) + " " + filter.user_zone}
                style={{ width: '60%', margin: 4 }}
                onChange={(val) => {
                  setFilter({
                    ...filter,
                    user_province: convertProvinceTextToId(val.split("_")[0]),
                    user_zone: val.split("_")[1],
                  })
                }}
              >
                {
                  filter.list_province.map(ele => {
                    return <Option value={ele.province + "_" + ele.zone}>{ele.province + " " + ele.zone}</Option>
                  })
                }
              </Select>
            </div>
          </div>
        } */}
        <div className='flex flex-col w-full' >
          <Tabs className='mb-4' value={tabindex} onChange={(event, newValue) => {
            setResult({
              total_fund: { text: "", value: [0, 0, 0], unit: 'คน' },
              total_inno: { text: "", value: [0, 0, 0], unit: 'คน' },
              total_research: { text: "", value: [0, 0, 0], unit: 'คน' },
              total: { text: "", value: [0, 0, 0], unit: 'คน' },
              total_school: { text: "", value: [0, 0, 0], unit: 'โรงเรียน' },
              cct: { text: "ทุนเสมอภาค", value: [0, 0, 0], unit: 'คน' },
              krt: { text: "ทุนครูรัก(ษ์)ถิ่น", value: [0, 0, 0], unit: 'คน' },
              vec: { text: "ทุนนวัตกรรมสายอาชีพชั้นสูง", value: [0, 0, 0], unit: 'คน' },
              tps: { text: "ทุนพระกนิษฐาสัมมาชีพ", value: [0, 0, 0], unit: 'คน' },
              target_group: { text: "ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน", value: [0, 0, 0], unit: 'คน' },
              tsqp: { text: "โครงการโรงเรียนพัฒนาตนเอง", value: [0, 0, 0], unit: 'โรงเรียน' },
              abe: { text: "การจัดการเชิงพื้นที่", value: [0, 0, 0], unit: 'คน' },
              fsql: { text: "โรงเรียนในโครงการ FSQL", value: [0, 0, 0], unit: 'โรงเรียน' },
              pisa: { text: "PISA for school (นักเรียนช้างเผือก)", value: [0, 0, 0], unit: 'คน' },
            })
            setTabIndex(newValue)
          }} centered>
            <Tab label={<span className='text-lg'>เปรียบเทียบตำบล</span>} />
            <Tab label={<span className='text-lg'>เปรียบเทียบโรงเรียน</span>} />
          </Tabs>
        </div>

        <div className='grid grid-cols-3 lg:grid-cols-4'>
          <div className='col-span-1 lg:col-start-2 lg:col-end-3 px-4'>
            <Select
              className='new-design-bold-select'
              showSearch
              optionFilterProp="children"
              placeholder={tabindex === 0 ? tumbon_view_filter.replace : school_view_filter.replace}
              defaultValue={tabindex === 0 ? tumbon_view_filter.value[0] : school_view_filter.value[0]}
              value={tabindex === 0 ? tumbon_view_filter.value[0] : school_view_filter.value[0]}
              style={{ width: '100%', marginBottom: '15px', textAlign: 'center' }}
              size={'large'}
              onChange={(val) => {
                if (tabindex === 0) {
                  setTumbonViewFilter({ ...tumbon_view_filter, value: [val, tumbon_view_filter.value[1], tumbon_view_filter.value[2]] })
                } else {
                  setSchoolViewFilter({ ...school_view_filter, value: [val, school_view_filter.value[1], school_view_filter.value[2]] })
                }
              }}
            >
              {
                tabindex === 0 ? [...new Set(filter.list_tumbon)].map(x => <Option value={x}>{x}</Option>) :
                  [...new Set(filter.list_school)].map(x => <Option value={x}>{x}</Option>)
              }
            </Select>
          </div>
          <div className='col-span-1 lg:col-start-3 lg:col-end-4 px-4'>
            <Select
              className='new-design-bold-select'
              showSearch
              optionFilterProp="children"
              placeholder={tabindex === 0 ? tumbon_view_filter.replace : school_view_filter.replace}
              defaultValue={tabindex === 0 ? tumbon_view_filter.value[1] : school_view_filter.value[1]}
              value={tabindex === 0 ? tumbon_view_filter.value[1] : school_view_filter.value[1]}
              style={{ width: '100%', marginBottom: '15px', textAlign: 'center' }}
              size={'large'}
              onChange={(val) => {
                if (tabindex === 0) {
                  setTumbonViewFilter({ ...tumbon_view_filter, value: [tumbon_view_filter.value[0], val, tumbon_view_filter.value[2]] })
                } else {
                  setSchoolViewFilter({ ...school_view_filter, value: [school_view_filter.value[0], val, school_view_filter.value[2]] })
                }
              }}
            >
              {
                tabindex === 0 ? [...new Set(filter.list_tumbon)].map(x => <Option value={x}>{x}</Option>) :
                  [...new Set(filter.list_school)].map(x => <Option value={x}>{x}</Option>)
              }
            </Select>
          </div>
          <div className='col-span-1 lg:col-start-4 lg:col-end-5 px-4'>
            <Select
              className='new-design-bold-select'
              showSearch
              optionFilterProp="children"
              placeholder={tabindex === 0 ? tumbon_view_filter.replace : school_view_filter.replace}
              defaultValue={tabindex === 0 ? tumbon_view_filter.value[2] : school_view_filter.value[2]}
              value={tabindex === 0 ? tumbon_view_filter.value[2] : school_view_filter.value[2]}
              style={{ width: '100%', marginBottom: '15px', textAlign: 'center' }}
              size={'large'}
              onChange={(val) => {
                if (tabindex === 0) {
                  setTumbonViewFilter({ ...tumbon_view_filter, value: [tumbon_view_filter.value[0], tumbon_view_filter.value[1], val] })
                } else {
                  setSchoolViewFilter({ ...school_view_filter, value: [school_view_filter.value[0], school_view_filter.value[1], val] })
                }
              }}
            >
              {
                tabindex === 0 ? [...new Set(filter.list_tumbon)].map(x => <Option value={x}>{x}</Option>) :
                  [...new Set(filter.list_school)].map(x => <Option value={x}>{x}</Option>)
              }
            </Select>
          </div>
        </div>
        <div className='grid grid-cols-3 lg:grid-cols-4'>
          <div className='col-span-1 lg:col-start-2 lg:col-end-3 px-4'>
            <MainCard student={result.total.value[0]} school={result.total_school.value[0]} unit_student={result.total.unit} unit_school={result.total_school.unit} />
          </div>
          <div className='col-span-1 lg:col-start-3 lg:col-end-4 px-4'>
            <MainCard student={result.total.value[1]} school={result.total_school.value[1]} unit_student={result.total.unit} unit_school={result.total_school.unit} />
          </div>
          <div className='col-span-1 lg:col-start-4 lg:col-end-5 px-4'>
            <MainCard student={result.total.value[2]} school={result.total_school.value[2]} unit_student={result.total.unit} unit_school={result.total_school.unit} />
          </div>
        </div>

        <div className='grid grid-cols-1 mt-6 mb-2'>
          <div className='col-start-1 col-end-2'>
            <h2 className='text-3xl font-bold text-[#fa6814] my-0 text-center lg:text-left'>ปี {filter.year}</h2>
          </div>
        </div>
        <div className='border-b-2 border-neutral-400 mx-auto my-6'></div>

        <div>
          {/* <h2 className='text-2xl font-bold'>ทุนสร้างโอกาส</h2> */}
          <div className='grid grid-cols-3 lg:grid-cols-4 my-1'>
            <div className='col-span-3 col-start-1 lg:col-end-2'>
              <h2 className='text-2xl font-bold m-0 text-center lg:text-left'>ทุนสร้างโอกาส</h2>
            </div>
            <div className='col-span-1 lg:col-start-2 lg:col-end-3 text-center'>
              <h2 className='text-2xl font-bold m-0'>{result.total_fund.value[0] ? `${result.total_fund.value[0].toLocaleString("en-US")} ${result.total_fund.unit}` : "-"}</h2>
            </div>
            <div className='col-span-1 lg:col-start-3 lg:col-end-4 text-center'>
              <h2 className='text-2xl font-bold m-0'>{result.total_fund.value[1] ? `${result.total_fund.value[1].toLocaleString("en-US")} ${result.total_fund.unit}` : "-"}</h2>
            </div>
            <div className='col-span-1 lg:col-start-4 lg:col-end-5 text-center'>
              <h2 className='text-2xl font-bold m-0'>{result.total_fund.value[2] ? `${result.total_fund.value[2].toLocaleString("en-US")} ${result.total_fund.unit}` : "-"}</h2>
            </div>
          </div>
        </div>
        <div className='border-dotted border-b border-neutral-400 mx-auto my-6'></div>
        <RowCell {...result.cct} />
        <RowCell {...result.krt} />
        <RowCell {...result.vec} />
        <RowCell {...result.tps} />
        <RowCell {...result.target_group} />
        <div className='border-b-2 border-neutral-400 mx-auto my-6'></div>
        <div>
          {/* <h2 className='text-2xl font-bold'>นวัตกรรมต้นแบบ</h2> */}
          <div className='grid grid-cols-3 lg:grid-cols-4 my-1'>
            <div className='col-span-3 col-start-1 lg:col-end-2'>
              <h2 className='text-2xl font-bold m-0 text-center lg:text-left'>นวัตกรรมต้นแบบ</h2>
            </div>
            <div className='col-span-1 lg:col-start-2 lg:col-end-3 text-center'>
              <h2 className='text-2xl font-bold m-0'>{result.total_inno.value[0] ? `${result.total_inno.value[0].toLocaleString("en-US")} ${result.total_inno.unit}` : "-"}</h2>
            </div>
            <div className='col-span-1 lg:col-start-3 lg:col-end-4 text-center'>
              <h2 className='text-2xl font-bold m-0'>{result.total_inno.value[1] ? `${result.total_inno.value[1].toLocaleString("en-US")} ${result.total_inno.unit}` : "-"}</h2>
            </div>
            <div className='col-span-1 lg:col-start-4 lg:col-end-5 text-center'>
              <h2 className='text-2xl font-bold m-0'>{result.total_inno.value[2] ? `${result.total_inno.value[2].toLocaleString("en-US")} ${result.total_inno.unit}` : "-"}</h2>
            </div>
          </div>
        </div>
        <div className='border-dotted border-b border-neutral-400 mx-auto my-6'></div>
        <RowCell {...result.tsqp} />
        <RowCell {...result.abe} />
        <div className='border-b-2 border-neutral-400 mx-auto my-6'></div>
        {/* <div>
          <div className='grid grid-cols-3 lg:grid-cols-4 my-1'>
            <div className='col-span-3 col-start-1 lg:col-end-2'>
              <h2 className='text-2xl font-bold m-0 text-center lg:text-left'>งานวิจัย</h2>
            </div>
            <div className='col-span-1 lg:col-start-2 lg:col-end-3 text-center'>
              <h2 className='text-2xl font-bold m-0'>{result.total_research.value[0] ? `${result.total_research.value[0].toLocaleString("en-US")} ${result.total_research.unit}` : "-"}</h2>
            </div>
            <div className='col-span-1 lg:col-start-3 lg:col-end-4 text-center'>
              <h2 className='text-2xl font-bold m-0'>{result.total_research.value[1] ? `${result.total_research.value[1].toLocaleString("en-US")} ${result.total_research.unit}` : "-"}</h2>
            </div>
            <div className='col-span-1 lg:col-start-4 lg:col-end-5 text-center'>
              <h2 className='text-2xl font-bold m-0'>{result.total_research.value[2] ? `${result.total_research.value[2].toLocaleString("en-US")} ${result.total_research.unit}` : "-"}</h2>
            </div>
          </div>
        </div>
        <div className='border-dotted border-b border-neutral-400 mx-auto my-6'></div>
        <RowCell {...result.fsql} />
        <RowCell {...result.pisa} /> */}
      </div>
      <a onClick={() => { setModalRef(true) }} className="font-[22px] mb-6 mt-6 text-gray-800 underline-offset-auto ">
        <InfoIcon /> แหล่งที่มาของข้อมูล
      </a>
      <Modal
        width={800}
        visible={ismodalref}
        // title="แหล่งที่มาของข้อมูล"
        onOk={() => setModalRef(false)}
        onCancel={() => setModalRef(false)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div>
          <div>
              <span className='text-lg font-bold'>แหล่งที่มาข้อมูล</span><br />
              <span className='pl-6'>ข้อมูลการช่วยเหลือกลุ่มเป้าหมายในแต่ละโครงการของ กสศ. ประกอบด้วย</span><br />
              <br />
              <span className='text-lg font-bold'>ข้อมูลนักเรียนในระบบการศึกษา</span><br />
              <span className='pl-6'>ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2566 </span><br />
              <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                  <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                      <ul className='bg-[#f0fdf4] rounded-md py-4'>
                          <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                          <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                          <li className='pl-6'>- กรมส่งเสริมการเรียนรู้</li>
                          <li className='pl-6'>- กองทัพบก</li>
                          <li className='pl-6'>- กองทัพเรือ</li>
                          <li className='pl-6'>- กองทัพอากาศ</li>
                          <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                          <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ </li>
                          <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์ </li>
                          <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์ </li>
                          <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                          <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                          <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                          <li className='pl-6'>- สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                          <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                      </ul>
                  </Panel>
              </Collapse>
              <br />
              <span className='text-lg font-bold'>ข้อมูลจำนวนเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (อายุ 3 - 17 ปี)</span><br />
              <span className='pl-6'>(1) ข้อมูลทะเบียนราษฎร์ ปี 2566 </span><br />
              <span className='pl-6'>(2) ข้อมูลจำนวนนักเรียนในแต่ละสังกัดปีการศึกษา 2566</span><br />
              <span className='pl-6'>(3) นำข้อมูลในข้อที่ (1) หักลบกับ (2) จะได้จำนวนเด็กที่ไม่มีข้อมูลในระบบการศึกษา ปี 2564</span><br />
              <br />
              <span className='text-lg font-bold'>ข้อมูลจำนวนสถานศึกษา</span><br />
              <span className='pl-6'>ข้อมูลจำนวนสถานศึกษาในแต่ละสังกัดปีการศึกษา 2566 </span><br />
              <Collapse style={{ marginLeft: '15px' }} ghost accordion={true} expandIcon={() => { return <></> }}>
                  <Panel header={<a>(คลิกเพื่อดูรายชื่อสังกัดที่นำมาใช้ในการประมวลผล)</a>} key="1" showArrow={false}>
                      <ul className='bg-[#f0fdf4] rounded-md py-4'>
                          <li className='pl-6'>- กรมกิจการเด็กและเยาวชน</li>
                          <li className='pl-6'>- กรมส่งเสริมการปกครองท้องถิ่น</li>
                          <li className='pl-6'>- กรมส่งเสริมการเรียนรู้</li>
                          <li className='pl-6'>- กองทัพบก</li>
                          <li className='pl-6'>- กองทัพเรือ</li>
                          <li className='pl-6'>- กองทัพอากาศ</li>
                          <li className='pl-6'>- กองบัญชาการตำรวจตระเวนชายแดน</li>
                          <li className='pl-6'>- มหาวิทยาลัยการกีฬาแห่งชาติ </li>
                          <li className='pl-6'>- โรงเรียนมหิดลวิทยานุสรณ์ </li>
                          <li className='pl-6'>- สถาบันบัณฑิตพัฒนศิลป์ </li>
                          <li className='pl-6'>- สถาบันวิชาการป้องกันประเทศ กองบัญชาการกองทัพไทย</li>
                          <li className='pl-6'>- สำนักการศึกษา กรุงเทพมหานคร</li>
                          <li className='pl-6'>- สำนักการศึกษา เมืองพัทยา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการการอาชีวศึกษา</li>
                          <li className='pl-6'>- สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน</li>
                          <li className='pl-6'>- สำนักงานปลัดกระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม</li>
                          <li className='pl-6'>- สำนักงานพระพุทธศาสนาแห่งชาติ</li>
                      </ul>
                  </Panel>
              </Collapse>
              <br />
              <span className='text-lg font-bold'>ทุนสร้างโอกาส</span><br />
              <span className='pl-6'><b>ทุนเสมอภาค</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือใน 6 สังกัด ได้แก่ สพฐ. อปท. ตชด. พศ. สช. และ กทม. ปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนครูรัก(ษ์)ถิ่น</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนนวัตกรรมสายอาชีพ</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนพระกนิษฐา</b> ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2566 </span><br />
              <span className='pl-6'><b>ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน</b> ข้อมูลจำนวนกลุ่มเป้าหมายที่ได้รับความช่วยเหลือในปีการศึกษา 2564</span>
              <br />
              <span className='text-lg font-bold'>นวัตกรรมต้นแบบ</span><br />
              <span className='pl-6'><b>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา </b> ข้อมูลการสำรวจแผนงาน 20 จังหวัดเสมอภาค ปี 2563</span><br />
              <span className='pl-6'><b>เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา</b> ข้อมูลการสำรวจแผนงานครูและเด็กนอกระบบ ปี 2563 </span><br />
              <span className='pl-6'><b>เด็กปฐมวัยยากจนใน ศพด.</b> ข้อมูลจำนวนเด็กปฐมวัยที่ได้รับความช่วยเหลือในพื้นที่ 20 จังหวัดเสมอภาค 	ในปีการศึกษา 2563 </span><br />
              <span className='pl-6'><b>โครงการ TSQP </b> ข้อมูลจำนวนโรงเรียนที่เข้าร่วมโครงการ ปีการศึกษา 2564 </span><br />
              <br />
              <span className='text-lg font-bold text-[#15803d]'>*ประมวลผล ณ เดือนสิงหาคม 2567</span><br />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MapAreaView
